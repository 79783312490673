<template>
  <div class="WalletMethod_root">
    <div class="WalletMethod_wrapper">
      <div class="md:flex md:justify-between md:items-start">
        <div class="grow rounded-[12px] bg-blue-800/[0.44] shadow-component-wrapper-root py-[15px] px-[15px] md:py-[30px] md:px-[40px]">
          <div key="form">
            <v-form
              ref="form"
              autocomplete="false"
            >
              <div class="grid grid-cols-3 gap-[20px]">
                <WalletMethodRow>
                  <Select
                    :items="countries"
                    :value="depositPbtPay.country"
                    placeholder="SELECT FROM LIST"
                    name="country"
                    label="Country *"
                    :rules="[...inputRules.required]"
                    componentName="VAutocomplete"
                    @change="handleInput(arguments[0].value, 'country')"
                  />
                </WalletMethodRow>
                <WalletMethodRow>
                  <TextFieldInput
                    :value="depositPbtPay.city"
                    placeholder="EX.DELAWARE"
                    name="city"
                    label="City *"
                    hideDetails="auto"
                    :rules="[...inputRules.required, ...inputRules.getCheckOnlyKirilicAndOnlyLetters ]"
                    autocomplete="off"
                    @input="handleInput(arguments[0], 'city')"
                  />
                </WalletMethodRow>
                <WalletMethodRow>
                  <TextFieldInput
                    type="number"
                    :value="depositPbtPay.postal_code"
                    placeholder="EX.8016"
                    name="postal_code"
                    label="Postal code *"
                    hideDetails="auto"
                    :rules="[() => depositPbtPay.postal_code.length <= 8 || 'Maximum 8 numbers']"
                    autocomplete="off"
                    @input="handleInput(arguments[0], 'postal_code')"
                  />
                </WalletMethodRow>
              </div>
              <WalletMethodRow>
                <TextFieldInput
                  :value="depositPbtPay.address_line1"
                  name="address_line1"
                  placeholder="EX. : Artistaro street, build 12 flat 126A"
                  label="Address *"
                  hideDetails="auto"
                  :rules="[...inputRules.required, ...inputRules.getCheckOnlyKirilic ]"
                  autocomplete="off"
                  @input="handleInput(arguments[0], 'address_line1')"
                />
              </WalletMethodRow>
            </v-form>
            <div class="flex flex-wrap items-center justify-center mt-[20px] md:mt-[35px] 2x:mt-[50px]">
              <Button
                class="mb-[10px] max-w-full w-full 2xl:max-w-[260px] 2xl:w-full 2xl:mr-[10px] 2xl:mb-[0]"
                text="Previous step"
                size="large"
                @click="handleCancel"
              />
              <OutlineButton
                class="2xl:max-w-[260px]"
                text="Next step"
                fullWidth
                currencyType
                :currency="currency"
                @click="handleSubmitDeposit"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import { allActiveDepositMethods } from '@/views/Application/Account/Exchange/Wallet/_helpers/depositRules';
import calculateOperationFee from '@/components/_common/Wallet/_helpers/calculateOperationFee';
import OutlineButton from '@/components/_common/FormElements/OutlineButton/OutlineButton';
import TextFieldInput from '@/components/_common/FormElements/TextFieldInput/TextFieldInput';
import { validateForm } from '@/mixins/validateForm';
import { walletDepositAndWithdraw } from '@/mixins/walletDepositAndWithdraw';
import inputRules from '@/utils/inputRules';
import Select from '@/components/_common/FormElements/Select/Select';
import WalletMethodRow from "@/components/WalletMethodRow"
import Countries from '@/utils/countries.js'
import Button from "@/components/_common/FormElements/Button/Button";

export default {
  name: "DepositGatewayStepTwo",
  mixins: [validateForm, walletDepositAndWithdraw],
  props: {
    currency: {
      type: String,
      required: false
    },
    methodName: {
      type: String,
      default: '',
    },
  },
  components: {
    OutlineButton,
    TextFieldInput,
    Select,
    WalletMethodRow,
    Button
  },
  data() {
    return {
      inputRules
    }
  },
  computed: {
    ...mapState('depositAndWithdrawal', [ 'depositPbtPay' ]),
    ...mapGetters('user', [ 'isVerified' ]),
    countries() {
      return Countries.map(item => ({value: item.country, key: item.code}))
    },
    prepareFeeInfo() {
      const feeObject = calculateOperationFee({
        currency: this.currency,
        type: 'GATEWAY',
        operation: 'deposit',
      });
      return feeObject;
    },
    // TODO: Скорее всего, это нужно выпилить, и оно тут уже не нужно.
    uahGetawayFeeString() {
      if (this.currency === 'UAH') {
        const { payment_provider_static_fee, percent_fee } = this.prepareFeeInfo;
        if (payment_provider_static_fee && percent_fee) return `${ percent_fee }% + ${ payment_provider_static_fee } ${ this.currency }`;
        return '';
      }
      return '';
    },
    // Это нужно для хелпера
    isMethodAvailable() {
      if (this.methodName) {
        const currentMethod = allActiveDepositMethods(this.currency)[ this.methodName ];
        const { disable_description } = currentMethod.CARD || currentMethod;
        if (disable_description === 'ACCOUNT_VERIFICATION_REQUIRED' && !this.isVerified) {
          return false;
        }
        return true;
      }
      return false;
    },
  },
  methods: {
    ...mapActions('depositAndWithdrawal', [ 'updateDepositPbtForm', 'apiGatewayAddressRequest', 'updateDepositPbtForm' ]),
    handleSubmitDeposit() {
      this.validateForm()
        .then(() => {
          this.updateDepositPbtForm({ value: 3, field: 'step' })
        });
    },
    handleCancel() {
      this.updateDepositPbtForm({ value: 1, field: 'step' })
    },
    handleInput(value, field) {
      this.updateDepositPbtForm({ value, field })
    },
  },
};
</script>

<style scoped>

</style>