<template>
  <div class="DepositCrypto_root">
    <div class="DepositCrypto_wrapper">
      <div class="DepositCrypto_container">
        <div
          class="DepositCrypto_verification"
          v-if="!isMethodAvailable"
        >
          <PassVerification operation="deposit"/>
        </div>
        <div
          class="DepositGateway_content"
          v-if="isMethodAvailable"
        >
          <!-- <div key="form" v-if="!isProviderFrameShown"> -->
          <div key="form">
            <v-form
              ref="form"
              autocomplete="false"
            >
              <TextFieldInput
                v-model="amount_to_spend"
                name="amount_to_spend"
                label="Amount"
                rightLabelString="FEE:"
                :rightLabelValue="computedFee"
                :rightLabelFee="calculateFeeToShow"
                hideDetails
                :rules="[...inputRules.required]"
                autocomplete="off"
              />
            </v-form>
            <OutlineButton
              class="outline-button-default outline-button-full-width outline-button mt-[20px]"
              text="Deposit"
              fullWidth
              currencyType
              :currency="currency"
              @click="handleSubmitDeposit"
            />
          </div>
          <!-- <div key="iframe" class="DepositGateway_iframe" v-if="isProviderFrameShown && providerFrameSrc.length">
            <iframe frameborder="0" border="0" :src="providerFrameSrc" width="100%" height="500px"></iframe>
          </div> -->
        </div>
        <div class="DepositCrypto_faq">
          <LimitFeePanel
            :method="methodName"
            operation="deposit"
            :limits="prepareLimitsInfo"
            :fees="prepareFeeInfo"
            :currency="currency"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import { allActiveDepositMethods } from '@/views/Application/Account/Exchange/Wallet/_helpers/depositRules';
import { getLimitsByCurrency } from '@/store/exchange/_helpers/exchangeLimits';
import calculateOperationFee from '@/components/_common/Wallet/_helpers/calculateOperationFee';
import LimitFeePanel from '@/components/_common/LimitFeePanel/LimitFeePanel';
import OutlineButton from '@/components/_common/FormElements/OutlineButton/OutlineButton';
import PassVerification from '@/components/_common/PassVerification/PassVerification';
import TextFieldInput from '@/components/_common/FormElements/TextFieldInput/TextFieldInput';
import { validateForm } from '@/mixins/validateForm';
import { walletDepositAndWithdraw } from '@/mixins/walletDepositAndWithdraw';
import inputRules from '@/utils/inputRules';
import { calculateFeeAmount } from '@/utils/helpers';

export default {
  props: {
    currency: {
      type: String,
      required: true,
      default: 'UAH',
    },
    methodName: {
      type: String,
      default: '',
    },
  },
  mixins: [validateForm, walletDepositAndWithdraw],
  components: {
    PassVerification,
    LimitFeePanel,
    OutlineButton,
    TextFieldInput,
  },
  data: () => ({
    inputRules,
    amount_to_spend: '',
    // isProviderFrameShown: false,
    providerFrameSrc: '',
  }),
  computed: {
    ...mapState('user', [ 'userInfo' ]),
    ...mapGetters('user', [ 'isVerified' ]),
    prepareLimitsInfo() {
      const limitsObject = getLimitsByCurrency({
        currency: this.currency,
        type: 'GATEWAY',
        operation: 'deposit',
      });
      return limitsObject;
    },
    prepareFeeInfo() {
      const feeObject = calculateOperationFee({
        currency: this.currency,
        type: 'GATEWAY',
        operation: 'deposit',
      });
      return feeObject;
    },
    // TODO: Скорее всего, это нужно выпилить, и оно тут уже не нужно.
    uahGetawayFeeString() {
      if (this.currency === 'UAH') {
        const { payment_provider_static_fee, percent_fee } = this.prepareFeeInfo;
        if (payment_provider_static_fee && percent_fee) return `${ percent_fee }% + ${ payment_provider_static_fee } ${ this.currency }`;
        return '';
      }
      return '';
    },
    // Это было перенесено из Geteway
    computedFee() {
      const feeObject = calculateOperationFee({
        currency: this.currency,
        type: 'GATEWAY',
        operation: 'deposit',
      });
      return this.computedFeeHelper(feeObject)
    },
    // Это нужно для хелпера
    calculateFee() {
      const feeObject = calculateOperationFee({
        currency: this.currency,
        type: 'GATEWAY',
        operation: 'deposit',
      });
      return feeObject;
    },
    isMethodAvailable() {
      if (this.methodName) {
        const currentMethod = allActiveDepositMethods(this.currency)[ this.methodName ];
        const { disable_description } = currentMethod;
        if (disable_description === 'ACCOUNT_VERIFICATION_REQUIRED' && !this.isVerified) {
          return false;
        }
        return true;
      }
      return false;
    },
    calculateFeeToShow() {
      return calculateFeeAmount({
        amount: this.amount_to_spend,
        feeObject: this.calculateFee,
      });
    },
  },
  methods: {
    ...mapActions('depositAndWithdrawal', [ 'apiGatewayAddressRequestProvider' ]),
    handleSubmitDeposit() {
      this.validateForm().then(() => {
        this.getDepositGatewayAddress();
      });
    },
    getDepositGatewayAddress() {
      let request = {
        currency: this.currency,
        amount_to_spend: this.amount_to_spend,
      };
      if (this.userInfo.account_type == 'BUSINESS') {
        // if (true) {
        request.additional_info = {
          deposit_email: this.userInfo.email,
        };
      }
      this.apiGatewayAddressRequestProvider(request).then(response => {
        if (response && response.data && response.data.addr) {
          window.open(response.data.addr, '_blank'), this.$router.push(`/exchange/wallet/deposit/${ this.currency }`);
          // this.isProviderFrameShown = true;
          // this.providerFrameSrc = response.data.addr;
        }
      });
    },
    handleMakePaymentRedirect() {
      this.amount_to_spend = '';
      // this.isProviderFrameShown = false;
      this.providerFrameSrc = '';
    },
  },
  mounted() {
    window.document.addEventListener('paymentFinished', this.handleMakePaymentRedirect, false);
  },
};
</script>

<style lang="scss" scoped>
@import './DepositGatewayProvider.scss';
</style>
