<template>
  <div class="WalletMethod_Confirm">
    <v-form
      ref="form"
      autocomplete="false"
    >
      <WalletMethodTitle>
        <div class="md:text-center md:mb-[10px] lg:mb-0 lg:text-left lg:text-[16px] 2xl:text-[21px]">Confirm deposit</div>
      </WalletMethodTitle>
      <div class="WalletMethod_separator !mb-[20px]"></div>
      <div class="lg:grid lg:grid-cols-[45%,_auto] lg:gap-[20px]">
        <div>
          <div class="flex flex-wrap items-end text-[16px] tracking-[0.4px] leading-[18px] mb-[13px]">
            <img src="@/assets/icons/icon-card-details-confirm-deposit.svg" class="mr-[5px]">
            <div>Card details</div>
          </div>
          <div>
            <div
              v-if="depositPbtPay.number"
              class="flex items-center flex-wrap mb-[10px] justify-between md:justify-start"
            >
              <div class="text-[14px] leading-[16px] text-gray-600 max-w-[139px] w-full text-left mr-[24px]">Card number:</div>
              <div class="text-white text-[14px] leading-[16px] font-normal text-right">{{ depositPbtPay.number }}</div>
            </div>
            <div
              v-if="depositPbtPay.expire_card_date"
              class="flex items-center flex-wrap mb-[10px] justify-between md:justify-start"
            >
              <div class="text-[14px] leading-[16px] text-gray-600 max-w-[139px] w-full text-left mr-[24px]">MM / YY:</div>
              <div class="text-white text-[14px] leading-[16px] font-normal">{{ depositPbtPay.expire_card_date }}</div>
            </div>
            <div
              v-if="depositPbtPay.holder_name"
              class="flex items-center flex-wrap mb-[10px] justify-between md:justify-start"
            >
              <div class="text-[14px] leading-[16px] text-gray-600 max-w-[139px] w-full text-left mr-[24px]">Name on card:</div>
              <div class="text-white text-[14px] leading-[16px] font-normal">{{ depositPbtPay.holder_name }}</div>
            </div>
            <div
              v-if="depositPbtPay.amount"
              class="flex items-center flex-wrap mb-[10px] justify-between md:justify-start"
            >
              <div class="text-[14px] leading-[16px] text-gray-600 max-w-[139px] w-full text-left mr-[24px]">Amount:</div>
              <div class="text-white text-[14px] leading-[16px] font-normal">{{ depositPbtPay.amount }}</div>
            </div>
          </div>
        </div>
        <div>
          <div class="flex flex-wrap items-end text-[16px] tracking-[0.4px] leading-[18px] mb-[13px]">
            <img src="@/assets/icons/icon-client-address-confirm-deposit.svg" class="mr-[5px]">
            <div>Client address</div>
          </div>
          <div>
            <div>
              <div
                v-if="depositPbtPay.country"
                class="flex items-center mb-[10px] justify-between md:justify-start"
              >
                <div class="text-[14px] leading-[16px] text-gray-600 mr-[24px] max-w-[139px] w-full text-left">Country:</div>
                <div class="text-white text-[14px] leading-[16px] font-normal">{{ getCountryName }}</div>
              </div>
              <div
                v-if="depositPbtPay.city"
                class="flex items-center mb-[10px] justify-between md:justify-start"
              >
                <div class="text-[14px] leading-[16px] text-gray-600 mr-[24px] max-w-[139px] w-full text-left">City:</div>
                <div class="text-white text-[14px] leading-[16px] font-normal">{{ depositPbtPay.city }}</div>
              </div>
              <div
                v-if="depositPbtPay.postal_code"
                class="flex items-center mb-[10px] justify-between md:justify-start"
              >
                <div class="text-[14px] leading-[16px] text-gray-600 mr-[24px] max-w-[139px] w-full text-left">Postal code:</div>
                <div class="text-white text-[14px] leading-[16px] font-normal">{{ depositPbtPay.postal_code }}</div>
              </div>
              <div
                v-if="depositPbtPay.address_line1"
                class="flex items-center mb-[10px] justify-between md:justify-start"
              >
                <div class="text-[14px] leading-[16px] text-gray-600 mr-[24px] max-w-[139px] w-full text-left">Address:</div>
                <div class="text-white text-[14px] leading-[16px] font-normal">{{ depositPbtPay.address_line1 }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="WalletMethod_separator !mb-[20px]"></div>
      <div class="flex items-center flex-wrap justify-center mt-[15px]">
        <Button
          class="mb-[10px] max-w-full w-full 2xl:max-w-[260px] 2xl:w-full 2xl:mr-[10px] 2xl:mb-[0]"
          text="Previous step"
          size="large"
          @click="handleCancel"
        />
        <OutlineButton
          class="2xl:max-w-[260px]"
          text="Confirm"
          fullWidth
          :disabled="depositPbtPay.isLoading"
          @click="handleSubmitDeposit"
        />
      </div>
    </v-form>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
import WalletMethodTitle from "@/components/WalletMethodTitle";
import Button from "@/components/_common/FormElements/Button/Button";
import OutlineButton from "@/components/_common/FormElements/OutlineButton/OutlineButton";
import { validateForm } from '@/mixins/validateForm';
import Countries from '@/utils/countries.js';
import { helpersGetNotificationPageUrl } from '@/utils/helpers';

export default {
  name: "DepositGatewayStepConfirm",
  props: {
    currency: {
      type: String,
      required: false
    },
    methodName: {
      type: String,
      default: '',
    },
  },
  mixins: [ validateForm ],
  components: {
    WalletMethodTitle,
    Button,
    OutlineButton
  },
  computed: {
    ...mapState('depositAndWithdrawal', [ 'depositPbtPay' ]),
    getCountryName() {
      return Countries.find(country => country.code === this.depositPbtPay.country).country
    }
  },
  methods: {
    ...mapActions('depositAndWithdrawal', [ 'apiGatewayAddressRequest', 'updateDepositPbtForm' ]),
    handleSubmitDeposit() {
      this.validateForm().then(() => {
        this.getDepositGatewayAddress();
      });
    },
    handleCancel() {
      this.updateDepositPbtForm({ value: 2, field: 'step' })
    },
    getDepositGatewayAddress() {
      const request = {
        comment: this.depositPbtPay.comment,
        currency: this.currency,
        amount: this.depositPbtPay.amount,
        payment_method: "CARD",
        additional_info: {
          urls: {
            success_url: helpersGetNotificationPageUrl("depositPbt"),
            fail_url: helpersGetNotificationPageUrl("depositPbt"),
            post_redirect: helpersGetNotificationPageUrl("depositPbt"),
          },
          card: {
            number: this.depositPbtPay.number.replaceAll( /\s/g, "" ),
            expire_month: this.depositPbtPay.expire_card_date.split('/')[0],
            expire_year: this.depositPbtPay.expire_card_date.split('/')[1],
            cv2: this.depositPbtPay.cv2,
            holder_name: this.depositPbtPay.holder_name,
            billing_details: {
              country: this.depositPbtPay.country,
              city: this.depositPbtPay.city,
              address_line1: this.depositPbtPay.address_line1,
              postal_code: this.depositPbtPay.postal_code,
            }
          }
        }
      }
      this.depositPbtPay.isLoading = true
      this.apiGatewayAddressRequest(request).then(response => {
        const {data:{data:{status}}} = response
        if (status !== "FAILED") {
          this.comment = ""
          this.amount = ""
          this.number = ""
          this.expire_month = ""
          this.expire_year = ""
          this.cv2 = ""
          this.first_name = ""
          this.last_name = ""
          this.country = ""
          this.city = ""
          this.address_line1 = ""
          this.postal_code = ""
        }
        if (status !== "PENDING") this.depositPbtPay.isLoading = false
      });
    },
  },
};
</script>

<style scoped lang="scss">
.custom-width-confirm-deposit-block {
  @media screen and (min-width: 992px) {
    width: 100%;
  }

  @media screen and (min-width: 1600px) {
    width: 45%;
  }
}
</style>