<template>
  <div class='DepositCrypto_root'>
    <div class='DepositCrypto_wrapper'>
      <div class='DepositCrypto_container'>
        <div class='DepositCrypto_form'>
          <div
            class='DepositCrypto_input'
            v-if='networksList.length'
          >
            <Select
              :value='selectedPaymentType'
              :items='networksList'
              name='payment_type'
              label='Network'
              :rules="[() => selectedPaymentType != 'Please choose network']"
              @change='handleChangePaymentNetworkType'
            />
          </div>
          <div
            class='DepositCrypto_formInner'
            v-if='isMethodAvailable'
          >
            <Input
              name='address'
              label='address'
              :value='depositWalletAddress'
              btnText='QR CODE'
              shouldCopyValue
              disabledColor='#fff'
              showQrIcon
              @clickBtn='handleClickInputBtn'
            />
            <div
              class='DepositCrypto_qrBg'
              v-if='showQr'
            >
              <qr-code
                v-if='depositWalletAddress'
                color='#2AF3F3'
                bg-color='transparent'
                :size='152'
                :text='depositWalletAddress'
              ></qr-code>
            </div>
            <OutlineButton
              class='outline-button-default outline-button-full-width outline-button mt-[20px]'
              text='copy address to clipboard'
              fullWidth
              currencyType
              :currency="currency"
              @click='handleCopyAddress'
            />
          </div>
          <!-- <div class="DepositCrypto_verification" v-else> -->
          <div
            class='DepositCrypto_verification'
            v-else-if="selectedPaymentType.value != 'Please choose network'"
          >
            <PassVerification operation='deposit' />
          </div>
        </div>
        <!-- <div class="DepositCrypto_faq mt-[20px] md:mt-[45px] 2xl:mt-[85px]"> -->
        <div class="DepositCrypto_faq">
          <CryptoLimitFeePanel
            :network="selectedPaymentType ? selectedPaymentType.value : ''"
            operation='deposit'
            :currency='currency'
            :fees='prepareFeeInfo'
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import { allActiveDepositMethods } from '@/views/Application/Account/Exchange/Wallet/_helpers/depositRules'
import Input from '@/components/_common/FormElements/Input/Input'
import OutlineButton from '@/components/_common/FormElements/OutlineButton/OutlineButton'
import CryptoLimitFeePanel from '@/components/_common/LimitFeePanel/CryptoLimitFeePanel'
import calculateOperationFee from '@/components/_common/Wallet/_helpers/calculateOperationFee'
import PassVerification from '@/components/_common/PassVerification/PassVerification'
import { copyToClipboard } from '@/utils/helpers'
import Select from '@/components/_common/FormElements/Select/Select'

export default {
  props: {
    currency: {
      type: String,
      required: true,
      default: 'BTC',
    },
  },
  components: {
    Input,
    OutlineButton,
    CryptoLimitFeePanel,
    PassVerification,
    Select,
  },
  data: () => ( {
    showQr: false,
    selectedPaymentType: {
      key: '',
      value: '',
    },
  } ),
  computed: {
    ...mapState( 'balance', [ 'userWallets' ] ),
    ...mapState( 'depositAndWithdrawal', [ 'depositCryptoForm' ] ),
    ...mapState( 'user', [ 'userDepositRules' ] ),
    isNetworksExist() {
      const depositRules = Object.keys( this.userDepositRules[ this.currency ].GATEWAY.P2P )
      // TODO: Get from BE list of Networks
      return depositRules.some( i => [ 'ERC20', 'TRC20', 'BEP20' ].includes( i ) )
    },
    networksList() {
      const processingRules = this.userDepositRules[ this.currency ].GATEWAY.P2P
      if ( this.isNetworksExist ) {
        let keys = Object.keys( processingRules )
        keys.push( keys.shift() )
        return keys.reduce(
          ( acc, network ) => {
            processingRules[ network ].is_deposit_enabled
              ? acc.push( { key: network, value: network } )
              : processingRules[ network ].disable_description !== 'DISABLED'
                ? acc.push( { key: network, value: network } )
                : acc
            return acc
          },
          [ { key: 'Please choose network', value: 'Please choose network', disabled: true } ],
        )
      }
      return []
    },
    walletData() {
      // if no networks return this.userWallets[this.currency]
      if ( !this.isNetworksExist ) {
        return this.userWallets[ this.currency ]
      }
      const { value: selectedNetwork } = this.selectedPaymentType
      return this.userWallets[ this.currency ][ selectedNetwork ]
    },
    depositWalletAddress() {
      const address = ( this.walletData && this.walletData.address ) || ''
      return address
    },
    prepareFeeInfo() {
      const { value: selectedNetwork } = this.selectedPaymentType
      const feeObject = calculateOperationFee( {
        currency: this.currency,
        type: 'GATEWAY',
        operation: 'deposit',
      } )
      if ( this.isNetworksExist ) return feeObject[ selectedNetwork ]
      return feeObject
    },
    isMethodAvailable() {
      const currentMethod = allActiveDepositMethods( this.currency )[ 'GATEWAY' ]
      var disable_description = ''
      // console.log(this.selectedPaymentType)
      /*if (this.selectedPaymentType.value == 'Please choose network') {
       return false;
       }*/
      if ( this.isNetworksExist && this.selectedPaymentType?.value ) {
        disable_description = currentMethod[ this.selectedPaymentType?.value ]?.disable_description
        // console.log(disable_description)
      } else {
        disable_description = currentMethod.disable_description
      }
      // console.log(disable_description)
      if ( disable_description === 'ACCOUNT_VERIFICATION_REQUIRED' || disable_description === undefined ) {
        // console.log('false')
        return false
      }
      return true
    },
  },
  methods: {
    ...mapActions( 'depositAndWithdrawal', [ 'updateDepositCryptoForm' ] ),
    handleCopyAddress() {
      copyToClipboard( this.depositWalletAddress )
    },
    handleClickInputBtn() {
      this.showQr = !this.showQr
    },
    checkIfNetworkExists() {
      if ( this.isNetworksExist ) {
        this.selectedPaymentType = this.networksList[ 0 ]
      }
    },
    handleChangePaymentNetworkType( object ) {
      this.selectedPaymentType = object
    },
  },
  mounted() {
    this.checkIfNetworkExists()
  },
}
</script>

<style lang='scss'>
@import "./DepositCrypto.scss";
</style>
