<template>
  <section class="Deposit_root">
    <div class="Deposit_wrapper">
      <RoundButton
        class="Deposit_backBtn"
        withIcon
        iconType="arrowLeft"
        text="back to wallet"
        @click="goBack"
      />
      <div class="Deposit_heading">
        Deposit
        <span>({{ currencyToDeposit }}) <SquareCoinIcon class="Deposit_icon" :currency="currencyToDeposit" /></span>
      </div>
      <div class="Deposit_methods">
        <swiper
          class="WalletMethod_cardsCarousel"
          ref="cardsSwiper"
          :options="swiperOptions"
          @slideChangeTransitionEnd="currentMethodSlide"
        >
          <swiper-slide
            v-for="(method, index) in availableMethods"
            :key="index"
          >
            <CurrencyCard
              :method="method"
              :currencyType="currencyType"
              :currency="currencyToDeposit"
              :activeMethod="activeMethod"
              @clickMethod="currencyCardClickHandler"
            />
          </swiper-slide>
          <div
            class="pagination"
            slot="pagination"
          ></div>
        </swiper>
      </div>
      <div class="Deposit_container" v-if="_userModuleLoaded">
        <DepositCrypto
          v-if="isCryptoDeposit"
          :currency="currencyToDeposit"
        />
        <DepositSwiftSepa
          v-if="isSepaActive && !isCryptoDeposit"
          :methodName="activeMethod"
          :currency="currencyToDeposit"
        />
        <DepositSwiftSepa
          v-if="isSwiftActive && !isCryptoDeposit"
          :methodName="activeMethod"
          :currency="currencyToDeposit"
        />
        <!-- <DepositSwiftSepa v-if="isSepaActive && !isCryptoDeposit" :methodName="activeMethod" :currency="currencyToDeposit" />
        <DepositSwiftSepa v-if="isSwiftActive && !isCryptoDeposit" :methodName="activeMethod" :currency="currencyToDeposit" /> -->
        <CashboxBreinrock
          v-if="activeMethod === 'CASHBOX_BREINROCK' && !isCryptoDeposit"
          :currency="currencyToDeposit"
        />
        <DepositGateway
          v-if="activeMethod === 'GATEWAY' && !isCryptoDeposit && !['UAH'].includes(currencyToDeposit)"
          :methodName="activeMethod"
          :currency="currencyToDeposit"
        />
        <DepositGatewayProvider
          v-if="activeMethod === 'GATEWAY' && !isCryptoDeposit && ['UAH'].includes(currencyToDeposit)"
          :methodName="activeMethod"
          :currency="currencyToDeposit"
        />
      </div>
      <div class="Deposit_container" v-else>
        <Loader />
      </div>
    </div>
  </section>
</template>

<script>
import { mapGetters, mapState, mapActions } from 'vuex';
import { allActiveDepositMethods } from '@/views/Application/Account/Exchange/Wallet/_helpers/depositRules';
import SquareCoinIcon from '@/components/_common/SquareCoinIcon/SquareCoinIcon';
import CurrencyCard from '@/components/_common/CurrencyCard/CurrencyCard';
import RoundButton from '@/components/_common/FormElements/RoundButton/RoundButton';
import Loader from '@/components/_common/Loader/Loader';
import DepositCrypto from './DepositCrypto/DepositCrypto';
import CashboxBreinrock from '../Deposit/CashboxBreinrock/CashboxBreinrock';
import DepositGatewayProvider from './DepositGatewayProvider/DepositGatewayProvider';
// import DepositSwiftSepa from './DepositSwiftSepa';
import DepositSepa from './DepositSepa/DepositSepa';
import DepositSwift from './DepositSwift/DepositSwift';
import DepositGateway from './DepositGateway/DepositGateway';

export default {
  components: {
    SquareCoinIcon,
    RoundButton,
    CurrencyCard,
    DepositCrypto,
    DepositGateway,
    DepositGatewayProvider,
    Loader,
    CashboxBreinrock,
    DepositSepa,
    DepositSwift,
    // DepositSwiftSepa,
  },
  watch: {
    '$route.params.currency': {
      handler(newVal) {
        this.currencyToDeposit = newVal;
      },
      immediate: true,
    },
    _userModuleLoaded: {
      handler(newVal) {
        if ( newVal && !this.availableMethods.includes(this.activeMethod)) this.activeMethod = this.availableMethods[ 0 ]
      },
      immediate: true,
    },
  },
  data() {
    return {
      currencyToDeposit: '',
      activeMethod: 'GATEWAY',
      swiperOptions: {
        watchOverflow: true,
        centerInsufficientSlides: true,
        spaceBetween: 20,
        pagination: {
          el: '.pagination',
          clickable: true,
        },
        breakpoints: {
          768: {
            slidesPerView: 4,
          },
          480: {
            slidesPerView: 3,
          },
          320: {
            slidesPerView: 1,
          }
        },
      }
    }
  },
  computed: {
    ...mapGetters('balance', ['userBalances']),
    ...mapState('user', ['_userModuleLoaded', '_userModulePending']),
    isSepaActive() {
      return this.activeMethod === 'SEPA';
    },
    isSwiftActive() {
      return this.activeMethod === 'SWIFT';
    },
    isCryptoDeposit() {
      return this.currencyType === 'crypto';
    },
    availableMethods() {
      // return Object.keys(allActiveDepositMethods(this.currencyToDeposit));
      // filter empty forms
      return Object.keys(allActiveDepositMethods(this.currencyToDeposit)).filter(item=>['BANK_COLLECTION', 'COINPAY_CODE', 'SALE_POINT'].indexOf(item) === -1);
    },
    currencyType() {
      if (this.userBalances) {
        const coin = this.userBalances.find(item => {
          return item.currency === this.currencyToDeposit;
        });
        const type = (coin && coin.currencyType) || '';
        return type;
      }
      return '';
    },
  },
  methods: {
    ...mapActions('depositAndWithdrawal', ['resetDepositAndWithdrawalModule']),
    goBack() {
      this.$router.go(-1);
    },
    currentMethodSlide() {
      if (window.innerWidth < 480) {
        const index = this.$refs.cardsSwiper.$el.getElementsByClassName('swiper-slide-active')[0].getElementsByClassName('CurrencyCard_root')[0].getAttribute('data-method')
        this.currencyCardClickHandler(index)
      }
    },
    currencyCardClickHandler(method) {
      this.activeMethod = method;
    },
  },
  mounted() {
    this.resetDepositAndWithdrawalModule();

  },
};
</script>

<style lang="scss">
@import '../WalletMethod.scss';
@import './Deposit.scss';
</style>
