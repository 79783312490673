<template>
  <div class="WalletMethod">
    <div class="WalletMethod_container">
      <div v-if="isMethodAvailable" class="WalletMethod_form">
        <!-- {{walletAdditionalData}} -->
        <div class="WalletMethod_table_row">
          <div class="WalletMethod_table_label">Bank name</div>
          <div class="WalletMethod_table_field">{{ walletAdditionalData.bank }}</div>
          <div class="WalletMethod_table_copy">
            <CopyBtn :valueToCopy="walletAdditionalData.bank" />
          </div>
        </div>
        <div class="WalletMethod_table_row">
          <div class="WalletMethod_table_label">Bank address</div>
          <div class="WalletMethod_table_field">{{ walletAdditionalData.bank_address }}</div>
          <div class="WalletMethod_table_copy">
            <CopyBtn :valueToCopy="walletAdditionalData.bank_address" />
          </div>
        </div>
        <div class="WalletMethod_table_row">
          <div class="WalletMethod_table_label">Account holder</div>
          <div class="WalletMethod_table_field">{{ walletAdditionalData.value }}</div>
          <div class="WalletMethod_table_copy">
            <CopyBtn :valueToCopy="walletAdditionalData.value" />
          </div>
        </div>
        <div class="WalletMethod_table_row">
          <div class="WalletMethod_table_label">Account number/IBAN</div>
          <div class="WalletMethod_table_field">{{ walletAdditionalData.iban }}</div>
          <div class="WalletMethod_table_copy">
            <CopyBtn :valueToCopy="walletAdditionalData.iban" />
          </div>
        </div>
        <div class="WalletMethod_table_row">
          <div class="WalletMethod_table_label">SWIFT</div>
          <div class="WalletMethod_table_field">{{ walletAdditionalData.value }}</div>
          <div class="WalletMethod_table_copy">
            <CopyBtn :valueToCopy="walletAdditionalData.value" />
          </div>
        </div>
        <div class="WalletMethod_table_row">
          <div class="WalletMethod_table_label">Reference</div>
          <div class="WalletMethod_table_field">{{ walletAdditionalData.value }}</div>
          <div class="WalletMethod_table_copy">
            <CopyBtn :valueToCopy="walletAdditionalData.value" />
          </div>
        </div>
        <div class="WalletMethod_hint">
          <div class="WalletMethod_warning">
            IMPORTANT: This code identifies your deposit with your account. Make sure to add your Reference as a comment to the wire transfer. Transactions without Reference might be lost!
          </div>
        </div>
      </div>
      <div v-else class="WalletMethod_form">
        <PassVerification :operation="action" />
      </div>
      <div class="WalletMethod_faq">
        <LimitFeePanel operation="deposit" :limits="prepareLimitsInfo" :fees="prepareFeeInfo" :currency="currency" :method="methodName" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import { allActiveDepositMethods } from '@/views/Application/Account/Exchange/Wallet/_helpers/depositRules';
import PassVerification from '@/components/_common/PassVerification/PassVerification';
import LimitFeePanel from '@/components/_common/LimitFeePanel/LimitFeePanel';
import calculateOperationFee from '@/components/_common/Wallet/_helpers/calculateOperationFee';
import { getLimitsByCurrency } from '@/store/exchange/_helpers/exchangeLimits';
import CopyBtn from '@/components/_common/CopyBtn/CopyBtn';

export default {
  props: {
    currency: {
      type: String,
      default: 'EUR',
    },
    action: {
      type: String,
      default: 'deposit',
    },
    methodName: {
      type: String,
      default: '',
    },
  },
  components: {
    LimitFeePanel,
    CopyBtn,
    PassVerification,
  },
  data: () => ({}),
  computed: {
    ...mapState('balance', ['userWallets']),
    ...mapGetters('user', ['isVerified']),
    prepareLimitsInfo() {
      const limitsObject = getLimitsByCurrency({
        currency: this.currency,
        type: 'SWIFT',
        operation: 'deposit',
      });
      return limitsObject;
    },
    prepareFeeInfo() {
      const feeObject = calculateOperationFee({
        currency: this.currency,
        type: 'SWIFT',
        operation: 'deposit',
      });
      return feeObject;
    },
    walletData() {
      return this.userWallets[this.currency];
    },
    walletAdditionalData() {
      if (this.walletData) {
        return this.walletData.additional_details;
      }
      return {};
    },
    isMethodAvailable() {
      if (this.methodName) {
        const currentMethod = allActiveDepositMethods(this.currency)[this.methodName];

        // need fix
        if (currentMethod == undefined) return true

        const { disable_description } = currentMethod;
        if (disable_description === 'ACCOUNT_VERIFICATION_REQUIRED' && !this.isVerified) {
          return false;
        }
        return true;
      }
      return false;
    },
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
@import './DepositSwift.scss';
</style>
