<template>
  <div class="WalletMethod_root">
    <div class="WalletMethod_wrapper">
      <div class="md:flex md:justify-between md:items-start">
        <div class="grow rounded-[12px] bg-blue-800/[0.44] shadow-component-wrapper-root py-[15px] px-[15px] md:py-[30px] md:px-[40px]">
          <div key="form">
            <v-form
              ref="form"
              autocomplete="false"
            >
              <WalletMethodTitle>
                <div class="md:text-center md:mb-[10px] lg:mb-0 lg:text-left lg:text-[16px] 2xl:text-[21px]">CARD DETAILS</div>
              </WalletMethodTitle>
              <WalletMethodSeparator class="!mb-[20px]" />
              <div class="3xl:flex 3xl:flex-wrap 3xl:justify-between mb-[20px] md:mb-[35px] 2x:mb-[50px]">
                <div class="3xl:max-w-[430px] 3xl:w-full">
                  <WalletMethodRow>
                    <TextFieldInput
                      ref="number"
                      :value="depositPbtPay.number"
                      v-maska="'#### #### #### ####'"
                      placeholder="0000 0000 0000 0000"
                      name="number"
                      label="Credit card number *"
                      hideDetails="auto"
                      :rules="[...inputRules.required, ...inputRules.creditCard16, ...inputRules.luhnRules]"
                      autocomplete="off"
                      @input="handleInput(arguments[0], 'number'); autoTab({value: arguments[0], length: 19, target: 'expire_card_date'})"
                    />
                  </WalletMethodRow>
                  <div class="3xl:grid 3xl:grid-cols-2 3xl:gap-[20px]">
                    <WalletMethodRow>
                      <TextFieldInput
                        ref="expire_card_date"
                        v-maska="'##/##'"
                        placeholder="MM / YY"
                        type="text"
                        :value="depositPbtPay.expire_card_date"
                        name="expire_card_date"
                        label="EXPIRY DATE *"
                        hideDetails="auto"
                        :rules="[...inputRules.required, ...inputRules.checkExpireDate]"
                        autocomplete="off"
                        @input="handleInput(arguments[0], 'expire_card_date'); autoTab({value: arguments[0], length: 5, target: 'cv2'})"
                      />
                    </WalletMethodRow>
                    <WalletMethodRow>
                      <TextFieldInput
                        ref="cv2"
                        v-maska="'###'"
                        placeholder="***"
                        type="password"
                        :value="depositPbtPay.cv2"
                        name="cv2"
                        label="CVV *"
                        hideDetails="auto"
                        :rules="[...inputRules.required, ...inputRules.creditCvv]"
                        autocomplete="off"
                        @input="handleInput(arguments[0], 'cv2'); autoTab({value: arguments[0], length: 3, target: 'holder_name'})"
                      />
                    </WalletMethodRow>
                  </div>
                  <WalletMethodRow>
                    <TextFieldInput
                      ref="holder_name"
                      :value="depositPbtPay.holder_name"
                      name="holder_name"
                      label="NAME ON CARD *"
                      placeholder="JOHN SMITH"
                      hideDetails="auto"
                      :rules="[...inputRules.required, ...inputRules.getCheckOnlyKirilicAndOnlyLetters ]"
                      autocomplete="off"
                      :disabled="fullNameDisabled"
                      @input="handleInput(arguments[0], 'holder_name')"
                    />
                  </WalletMethodRow>
                </div>
                <div class="3xl:pl-[45px] 3xl:flex 3xl:flex-col 3xl:justify-center pt-[16px] 3xl:pt-0 border-t-[2px] 3xl:border-t-0 3xl:border-l-[2px] border-blue-500/[0.2]">
                  <WalletMethodRow>
                    <TextFieldInput
                      type="number"
                      :value="depositPbtPay.amount"
                      name="amount"
                      label="Amount *"
                      rightLabelString="FEE:"
                      :rightLabelValue="computedFee"
                      :rightLabelFee="calculateFeeToShow"
                      hideDetails="auto"
                      autocomplete="off"
                      @input="handleInput(arguments[0], 'amount')"
                    />
                  </WalletMethodRow>
                  <div class="text-[14px] leading-[16px] text-center mb-[15px] uppercase">FAST TOP-UP:</div>
                  <div class="flex items-center justify-center min-h-[20px]">
                    <div
                      class="w-[60px] h-[30px] inline-flex justify-center items-center p-[5px] bg-blue-700/[0.03] hover:bg-blue-300/[0.1] text-blue-700 hover:text-blue-300 dark:bg-blue-500/[0.2] dark:text-white/[0.27] dark:hover:shadow-btn-hover-default dark:hover:bg-blue-500 dark:hover:text-blue-800 font-medium transition mr-[10px] last:mr-0 rounded-[2px] text-[11px] leading-[13px] rounded-[6px] cursor-pointer"
                      v-for="item in enterDepositValue"
                      :key="item.id"
                      @click="handleInput(item.value, 'amount')"
                    >
                      {{ item.text }} {{ currency }}
                    </div>
                  </div>
                </div>
              </div>
            </v-form>
            <div class="flex items-center flex-wrap justify-center mt-[15px]">
              <OutlineButton
                class="WalletMethod_copyBtn w-full max-w-[530px]"
                text="Next step"
                currencyType
                :currency="currency"
                @click="handleSubmitDeposit"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import calculateOperationFee from '@/components/_common/Wallet/_helpers/calculateOperationFee';
import { mapState, mapGetters, mapActions } from 'vuex';
import OutlineButton from '@/components/_common/FormElements/OutlineButton/OutlineButton';
import TextFieldInput from '@/components/_common/FormElements/TextFieldInput/TextFieldInput';
import { validateForm } from '@/mixins/validateForm';
import inputRules from '@/utils/inputRules';
import Select from '@/components/_common/FormElements/Select/Select';
import WalletMethodRow from "@/components/WalletMethodRow"
import WalletMethodTitle from "@/components/WalletMethodTitle";
import WalletMethodSeparator from "@/components/WalletMethodSeparator";
import { walletDepositAndWithdraw } from '@/mixins/walletDepositAndWithdraw'
import { calculateFeeAmount } from '@/utils/helpers';

export default {
  name: "DepositGatewayStepOne",
  props: {
    currency: {
      type: String,
      required: false
    },
    methodName: {
      type: String,
      default: '',
    },
  },
  mixins: [validateForm, walletDepositAndWithdraw],
  components: {
    WalletMethodSeparator,
    WalletMethodTitle,
    OutlineButton,
    TextFieldInput,
    Select,
    WalletMethodRow,
  },
  data() {
    return {
      inputRules,
      fullNameDisabled: false,
      enterDepositValue: [
        {
          id: 1,
          text: '100',
          value: 100
        },
        {
          id: 2,
          text: '250',
          value: 250
        },
        {
          id: 3,
          text: '500',
          value: 500
        },
        {
          id: 4,
          text: '1000',
          value: 1000
        }
      ]
    }
  },
  computed: {
    ...mapState('depositAndWithdrawal', [ 'depositPbtPay' ]),
    ...mapGetters('user', ['getUserInfoFullName']),
    currentStepComponent() {
      const step = this.depositPbtPay.step
      switch (step) {
        case 1:
          return 'DepositGatewayStepOne';
        case 2:
          return 'DepositGatewayStepTwo';
        case 3:
          return 'DepositGatewayStepConfirm';
        default:
          return 1;
      }
    },

    calculateFeeToShow() {
      return calculateFeeAmount({
        amount: this.depositPbtPay.amount,
        feeObject: this.calculateFee,
      });
    },

    computedFee() {
      const feeObject = calculateOperationFee({
        currency: this.currency,
        type: 'GATEWAY',
        operation: 'deposit',
      });
      return this.computedFeeHelper(feeObject)
    },
    // Это нужно для хелпера
    calculateFee() {
      const feeObject = calculateOperationFee({
        currency: this.currency,
        type: 'GATEWAY',
        operation: 'deposit',
      });
      return feeObject;
    },
  },
  methods: {
    ...mapActions('depositAndWithdrawal', [ 'updateDepositPbtForm' ]),
    handleSubmitDeposit() {
      this.validateForm()
        .then(() => {
          this.updateDepositPbtForm({ value: 2, field: 'step' })
        });
    },
    handleInput(value, field) {
      this.updateDepositPbtForm({ value, field })
    },
    autoTab({value, length, target}) {
      if (value.length >= length) {
        this.$refs[target].$el.querySelector('input:not([type=hidden]),textarea:not([type=hidden])').focus()
      }
    }
  },
  mounted() {
    if (this.getUserInfoFullName) {
      // this.updateDepositPbtForm({ value: this.getUserInfoFullName, field: 'holder_name' })
      this.$nextTick(() => this.updateDepositPbtForm({ value: this.getUserInfoFullName, field: 'holder_name' }))
      this.fullNameDisabled = true
    }
  }
};
</script>